// TODO variable region, such as en-GB or fr-FR

import { CurrencyEnum } from '../../types/Currency'
import { PayoutCryptoCurrency, PayoutCurrency } from '../../types/Payout'

export const formatCurrency = (
  num: number,
  digitAfterDecimalPoint = 0,
  divideByHundred: boolean = false,
  currency: CurrencyEnum | PayoutCurrency,
  locale: string = 'en-US',
): string => {
  const isCryptoCurrency = Object.values(PayoutCryptoCurrency).includes(
    currency as PayoutCryptoCurrency,
  )
  if (isCryptoCurrency) {
    const formatterCrypto = new Intl.NumberFormat(locale, {
      minimumFractionDigits: digitAfterDecimalPoint,
      maximumFractionDigits: digitAfterDecimalPoint,
    })

    const stringNumber = formatNumber(
      num,
      digitAfterDecimalPoint,
      divideByHundred,
    )
    const formattedNumber = formatterCrypto.format(Number(stringNumber))

    return `${formattedNumber} ${currency}`
  } else {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: digitAfterDecimalPoint,
    })

    return formatter.format(divideByHundred ? num / 100 : num)
  }
}

export const formatPrice = (price: number): number => {
  return Math.round((price / 100) * 100) / 100
}

export const formatNumber = (
  num: number,
  digitAfterDecimalPoint = 0,
  divideByHundred: boolean = false,
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: digitAfterDecimalPoint,
  })
  return divideByHundred ? formatter.format(num / 100) : formatter.format(num)
}

export const formatPercentage = (
  num: number,
  digitAfterDecimalPoint = 1,
  divideHundred: boolean,
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: digitAfterDecimalPoint,
  })

  const fractionalNumber = num / 100

  return formatter.format(
    divideHundred ? fractionalNumber / 100 : fractionalNumber,
  )
}
