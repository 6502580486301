import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { profitabilitySimulatorCalculation } from '../../../lib/utils/profitabilitySimulator'
import { formatCurrency } from '../../../lib/utils/formatNumber'
import { CurrencyEnum } from '../../../types/Currency'
import useLocale from '../../../hooks/useLocale'

export default function InvestmentSimulationBento() {
  const { t } = useTranslation('home')
  const { locale } = useLocale()

  const [initialInvestment, setInitialInvestment] = useState<number>(5000)
  const [yearlyYield, setYearlyYield] = useState<number>(600)
  const [yearlyPriceAppreciation, setYearlyPriceAppreciation] =
    useState<number>(350)
  const [totalPeriod, setTotalPeriod] = useState<number>(15)

  //todo: can be used to create a compounded bar chart
  const [totalYieldGain, setTotalYieldGain] = useState<number>(0)
  const [totalPriceAppreciation, setTotalPriceAppreciation] =
    useState<number>(0)
  const [totalProfitability, setTotalProfitability] = useState<number>(0)

  const handleInitialInvestmentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInitialInvestment(Number(e.target.value))
  }

  const handleYearlyYieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYearlyYield(Number(e.target.value))
  }

  const handleYearlyPriceAppreciationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setYearlyPriceAppreciation(Number(e.target.value))
  }

  const handleTotalPeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTotalPeriod(Number(e.target.value))
  }

  useEffect(() => {
    const { totalYieldGain, totalPriceAppreciation, totalProfitability } =
      profitabilitySimulatorCalculation(
        Number(initialInvestment),
        Number(yearlyYield),
        Number(yearlyPriceAppreciation),
        Number(totalPeriod),
      )
    setTotalYieldGain(totalYieldGain)
    setTotalPriceAppreciation(totalPriceAppreciation)
    setTotalProfitability(totalProfitability)
  }, [initialInvestment, yearlyYield, yearlyPriceAppreciation, totalPeriod])

  return (
    <div className="relative flex h-full w-full flex-col items-center">
      <img
        src="/img/icons/3d/locker-sm-min.png"
        className="absolute -left-10 -top-10 z-10 h-20 w-auto -rotate-6"
        alt="locker icon 3d"
      />

      <img
        src="/img/icons/3d/chart-sm-min.png"
        className="absolute -right-14 top-[30%] z-10 h-36 w-auto rotate-[24deg]"
        alt="chart icon 3d"
      />

      <img
        src="/img/icons/3d/dollar-coin-sm-min.png"
        className="absolute -left-8 bottom-0 z-10 h-28 w-auto -rotate-6"
        alt="dollar icon 3d"
      />

      <div className="z-10">
        <div>
          <p className="text-center font-medium text-neutralBlack">
            {t('bento.investmentSimulationBento.title')}
          </p>
        </div>
        <div className="w-full grow px-6 pt-3">
          <div className="relative w-full">
            <p className="text-xs font-bold text-neutralBlack/80">
              {t('bento.investmentSimulationBento.sliders.initialInvestment')}
            </p>
            <input
              type="range"
              min="10"
              max="10000"
              step="10"
              defaultValue={initialInvestment}
              className="h-2 w-full cursor-pointer appearance-none rounded-lg border border-neutralBlack/10 bg-gray-200"
              onChange={handleInitialInvestmentChange}
            />
            <p className="text-center font-medium">
              {formatCurrency(
                initialInvestment,
                0,
                false,
                CurrencyEnum.EUR,
                locale,
              )}
            </p>
          </div>

          <div className="relative w-full">
            <p className="text-xs font-bold text-neutralBlack/80">
              {t('bento.investmentSimulationBento.sliders.netRentalYield')}
            </p>
            <input
              type="range"
              min="100"
              max="2500"
              step="10"
              defaultValue={yearlyYield}
              className="h-2 w-full cursor-pointer appearance-none rounded-lg border border-neutralBlack/10 bg-gray-200"
              onChange={handleYearlyYieldChange}
            />
            <p className="text-center font-medium">
              {Number(yearlyYield) / 100}%
            </p>
          </div>

          <div className="relative w-full">
            <p className="text-xs font-bold text-neutralBlack/80">
              {t(
                'bento.investmentSimulationBento.sliders.yearlyPriceAppreciation',
              )}
            </p>
            <input
              type="range"
              min="50"
              max="1000"
              step="10"
              defaultValue={yearlyPriceAppreciation}
              className="h-2 w-full cursor-pointer appearance-none rounded-lg border border-neutralBlack/10 bg-gray-200"
              onChange={handleYearlyPriceAppreciationChange}
            />
            <p className="text-center font-medium">
              {Number(yearlyPriceAppreciation) / 100}%
            </p>
          </div>

          <div className="relative w-full">
            <p className="text-xs font-bold text-neutralBlack/80">
              {t('bento.investmentSimulationBento.sliders.estimatedIncomeOn')}
            </p>
            <input
              type="range"
              min="5"
              max="25"
              step="1"
              defaultValue={totalPeriod}
              className="h-2 w-full cursor-pointer appearance-none rounded-lg border border-neutralBlack/10 bg-gray-200"
              onChange={handleTotalPeriodChange}
            />
            <p className="text-center font-medium">
              {Number(totalPeriod)} {t('bento.investmentSimulationBento.years')}
            </p>
          </div>

          <div className="mb-3 mt-3 rounded-md bg-purpleGradient pb-3 pt-3">
            <p className="font-sm text-center font-light text-neutralWhite/80">
              {t('bento.investmentSimulationBento.estimatedGains')}
            </p>
            <p className="text-center font-medium text-white ">
              {formatCurrency(
                totalProfitability,
                0,
                false,
                CurrencyEnum.EUR,
                locale,
              )}
            </p>
          </div>
        </div>
        <div>
          <p className="text-center text-xs text-neutralBlack/80">
            {t('bento.investmentSimulationBento.helpText')}
          </p>
        </div>
      </div>
    </div>
  )
}
